import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import AddToCalendar from 'react-add-to-calendar';
import './reactAddToCalendar.css';
import { Button, Title1 as StyledTitle1 } from './v3/styledComponents';
import EventImagesContainer from './EventImagesContainer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.navy};
  box-sizing: border-box;
  padding: ${({ isTop }) => isTop ? 0 : 149}px 97px 140px;
  max-width: 1440px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding: ${({ isTop }) => isTop ? 0 : 52}px 25px 50px;
    max-width: unset;
    width: 100%;
  }
`;

const Title1 = styled(StyledTitle1)`
  margin-bottom: 30px;
`;

const SubTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 26px;
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 80px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 23px;
    margin-bottom: 50px;
  }
`;

const SubTitle = styled.div`
  margin: 0px 10px;
`;

const Line = styled.div`
  width: 30px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.green};
`;

const Text = styled.div`
  font-family: Roboto;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin-bottom: ${({ isBottomText }) => isBottomText ? 79 : 80}px;
  padding: 0px ${({ isBottomText }) => isBottomText ? 150 : 70}px;
  ${({ isBottomText }) => isBottomText && 'margin-top: -20px;'}
  max-width: 850px; 

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: ${({ isBottomText }) => isBottomText ? 25 : 50}px;
    padding: 0px;
    ${({ isBottomText }) => isBottomText && 'margin-top: -6px;'}
  }
`;

const EventContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const EventTitle = styled.div`
  font-family: Libre Baskerville;
  font-weight: ${({ isSubTitle }) => isSubTitle ? 300 : 'bold'};
  font-size: ${({ isSubTitle }) => isSubTitle ? 18 : 26}px;
  line-height: ${({ isSubTitle }) => isSubTitle ? 38 : 36}px;
  margin-bottom: ${({ isSubTitle }) => isSubTitle ? 20 : 10}px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: ${({ isSubTitle }) => isSubTitle ? 14 : 18}px;
    line-height: ${({ isSubTitle }) => isSubTitle ? 19 : 23}px;
  }
`;

const A = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.navy};
`;

const CanceledSpan = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.colors.lightGrey6};
  margin-left: 10px;
`;

const icon = { textOnly: 'none' };

const Markdown = styled(ReactMarkdown)`
  width: 100%;
  ${({ isSubText }) => isSubText && 'font-style: italic;'}
  font-family: Roboto;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: ${({ isSubText, hasSubText }) => {
    if (isSubText) return 30;
    return hasSubText ? 20 : 30;
  }}px;
  box-sizing: border-box;
  & > p {
    margin: 0px;
  }

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const EventText = styled(ReactMarkdown)`
  width: 100%;
  ${({ isSubText }) => isSubText && 'font-style: italic;'}
  font-family: Roboto;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: ${({ isSubText, hasSubText }) => {
    if (isSubText) return 30;
    return hasSubText ? 20 : 30;
  }}px;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Link = ({ href, children }) => (<A href={href} target="_blank">{children}</A>);
Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const renderEvents = (now, list, shouldApplyLazyLoadOnFirstImage) => (
  list.map(({
    eventTitle, eventSubTitle, eventImage1, eventImage2, eventAddress, eventDescription, eventSubText, eventBegin, eventEnd, eventAreMobileImagesReversed,
  }, index) => {
    const isPastEvent = moment(eventEnd).isBefore(now, 'day');
    const images = [eventImage1];
    if (eventImage2) images.push(eventImage2);
    return (
      <EventContainer key={eventTitle}>
        <EventTitle>
          {eventTitle}
          {isPastEvent ? <CanceledSpan>Terminé</CanceledSpan> : null}
        </EventTitle>
        <EventTitle isSubTitle>{eventSubTitle}</EventTitle>
        <Markdown renderers={{ link: Link }} plugins={[gfm]}>{eventDescription}</Markdown>
        {!!eventSubText && <EventText isSubText>{eventSubText}</EventText>}
        {!isPastEvent && (
          <AddToCalendar
            event={{
              location: eventAddress,
              startTime: eventBegin,
              endTime: eventEnd,
              title: eventTitle,
              description: eventSubTitle,
            }}
            buttonLabel="+ Ajouter à votre agenda"
            icon={icon}
          />
        )}
        <EventImagesContainer
          isLast={index === list.length - 1}
          images={images}
          alt={eventTitle}
          isLoadingLazy={index > 0 || shouldApplyLazyLoadOnFirstImage}
          areMobileImagesReversed={eventAreMobileImagesReversed}
        />
      </EventContainer>
    );
  })
);

const renderSubTitle = (title) => (
  <SubTitleContainer>
    <Line />
    <SubTitle>{title}</SubTitle>
    <Line />
  </SubTitleContainer>
);

const renderFilteredEvents = (now, title, list, mustSortChronologically = false, shouldApplyLazyLoadOnFirstImage = false) => {
  if (list.length === 0) return null;
  const sortedList = list.sort((eventA, eventB) => (
    mustSortChronologically ? new Date(eventB.eventBegin) - new Date(eventA.eventBegin) : new Date(eventA.eventBegin) - new Date(eventB.eventBegin)
  ));
  return (
    <>
      {renderSubTitle(title)}
      {renderEvents(now, sortedList, shouldApplyLazyLoadOnFirstImage)}
    </>
  );
};

const Events = ({ events }) => {
  const now = moment();
  const comingEvents = events.filter(({ eventBegin }) => moment(eventBegin).isAfter(now, 'day'));
  const inProgressEvents = events.filter(({ eventBegin, eventEnd }) => moment(now).isBetween(eventBegin, eventEnd, 'day', '[]'));
  const pastEvents = events.filter(({ eventEnd }) => moment(eventEnd).isBefore(now, 'day'));
  return (
    <Container isTop>
      <Title1>Nos événements</Title1>
      <Text>
        Atelier broderie, transformations de robe de mariée, retouches et réparations en faveur du Green Friday,
        création d’accessoires sur-mesure... Tout au long de l’année nos Tillistes multiplient les activités en
        boutiques chez nos marques partenaires et vous dévoilent leurs différents savoir-faire.
      </Text>

      {renderFilteredEvents(now, 'Ateliers à venir', comingEvents)}
      {renderFilteredEvents(now, 'Ateliers en cours', inProgressEvents, comingEvents.length > 0)}
      {renderFilteredEvents(now, 'Ateliers passés', pastEvents, true, comingEvents.length > 0 || inProgressEvents.length > 0)}

      <Text isBottomText>
        Vous n’avez pas le temps de vous déplacer lors de nos événements.
        Découvrez notre carte cadeau : création d’accessoires,
        upcycling, personnalisation de vêtements et d’accessoires.
      </Text>

      <A href="https://cadeau.tilli.fr" target="_blank" rel="noopener">
        <Button navy>Offrir la carte cadeau</Button>
      </A>
    </Container>
  );
};

Events.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
};

export default Events;
