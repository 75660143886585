import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Container = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: ${({ isLast }) => isLast ? 150 : 100}px;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    display: flex;
    width: 100%;
    flex-direction: ${({ mobileReversed }) => mobileReversed ? 'column-reverse' : 'column'};
    justify-content: center;
    margin-bottom: 80px;
    max-height: unset;
  }
`;

const EventImgContainer = styled.li`
  display: table-cell;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}%;
  padding: 0px;
  &:last-child:not(:first-child) {
    padding-left: 20px;
  }
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    &:last-child:not(:first-child) {
      padding-left: 0px;
    }
    padding-left: 0px;
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 19px;
  }
`;

const getImagesHeightAndWidths = (images) => {
  const imagesHeight = (images.map((image) => {
    const imageIt = getImage(image);
    return imageIt?.height || 550;
  }).reduce((min, height) => Math.min(height, min), 550));
  if (images.length === 1) return ({ height: imagesHeight, widths: ['100%'] });
  const image1 = getImage(images[0]);
  const image2 = getImage(images[1]);
  const width1 = (imagesHeight / image1.height) * image1.width;
  const width2 = (imagesHeight / image2.height) * image2.width;
  const sumWidths = width1 + width2 + 20;
  return ({ height: imagesHeight, widths: [(width1 * 100) / sumWidths, (width2 * 100) / sumWidths] });
};

const EventImagesContainer = ({
  images, isLast, alt, isLoadingLazy, areMobileImagesReversed,
}) => {
  const { height, widths } = getImagesHeightAndWidths(images);

  return (
    <Container isLast={isLast} mobileReversed={areMobileImagesReversed}>
      {images.map((image, index) => {
        const gatsbyImage = getImage(image);

        return (
          <EventImgContainer key={image.childImageSharp.id} height={height} width={widths[index]}>
            <GatsbyImage
              image={gatsbyImage}
              alt={alt}
              placeholder="blurred"
              loading={isLoadingLazy ? 'lazy' : 'eager'}
              layout="fullWidth"
              style={{ height: '100%', width: '100%' }}
            />
          </EventImgContainer>
        );
      })}
    </Container>
  );
};

EventImagesContainer.propTypes = {
  isLast: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  alt: PropTypes.string.isRequired,
  isLoadingLazy: PropTypes.bool.isRequired,
  areMobileImagesReversed: PropTypes.bool.isRequired,
};

export default EventImagesContainer;
