import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Footer from '../components/home/v3/Footer';
import MenuBar from '../components/home/MenuBar';
import Events from '../components/home/Events';
import { colors, Container as ContainerV3, Content } from '../components/home/v3/styledComponents';
import Layout from '../layouts/index';

const Container = styled(ContainerV3)`
  background-color: ${colors.white};
`;

class EventsTemplate extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    const { pageContext: { events } } = this.props;
    return (
      <Layout>
        <Container>
          <MenuBar />
          <Content>
            <Events events={events} />
          </Content>
          <Footer />
        </Container>
      </Layout>
    );
  }
}

EventsTemplate.propTypes = {
  pageContext: PropTypes.shape({
    events: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default EventsTemplate;
